import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive } from '../../util/search';
import { propTypes } from '../../util/types';
import { arrayMove } from '../../util/data';
import { generateSubCategoryFilter } from '../../util/filters/primary-filters';
import { generateSecondaryFilters } from '../../util/filters/secondary-filters';
import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  SortBy,
} from '../../components';

import FilterComponent from './FilterComponent';
import { validFilterParams } from './SearchPage.helpers';

import css from './SearchPage.module.css';

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

// Category IDs
const BIKES_CATEGORY = 'bikes';
const FRAMES_CATEGORY = 'frames';

// Filter IDs
const SELL_CATEGORIES_FILTER = 'sell-categories';
const RENT_CATEGORIES_FILTER = 'rent-categories';
const DATES_FILTER = 'dates';
const BRAND_FILTER = 'brand';
const FEATURES_FILTER = 'features';
const STOCK_FILTER = 'stock';
const MOBILE_STOCK_FILTER = 'mobile-stock';

// Filter type
const DEFAULT_FILTER_TYPE = 'default';

// Listing preference
const RENT_PREFERENCE = 'rent';

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */
class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { isSecondaryFiltersOpen: false, currentQueryParams: props.urlQueryParams };

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);

    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;

    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const subCategory = urlQueryParams['pub_subcategory'];

    const filterParamNames = filterConfig.map(f => f.queryParamNames);
    const filterQueryParamNames = subCategory
      ? filterParamNames.concat(Array(['pub_subcategory']))
      : filterParamNames;

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Get the prev and new preference value
        const prevPreference = prevState.currentQueryParams.pub_preference;
        const newPreference = updatedURLParams.pub_preference;

        // Get the prev and new category value
        const prevCategory = prevState.currentQueryParams.pub_category;
        const newCategory = updatedURLParams.pub_category;

        // If prev and new preference or category are not the
        // same we let component know that the value was changed
        const preferenceWasChanged =
          typeof newPreference !== 'undefined' && prevPreference !== newPreference;
        const categoryWasChanged =
          typeof newCategory !== 'undefined' && prevCategory !== newCategory;

        // Current search params
        const currentQueryParams = {
          ...mergedQueryParams,
          ...updatedURLParams,
          address,
          bounds,
        };

        if (preferenceWasChanged) {
          delete currentQueryParams.pub_category;
          delete currentQueryParams.pub_subcategory;
          delete currentQueryParams.stock;
        } else if (categoryWasChanged) {
          delete currentQueryParams.pub_subcategory;
          delete currentQueryParams.pub_gender;
          delete currentQueryParams.pub_type;
          delete currentQueryParams.pub_style;
        }

        if (newPreference === 'rent') {
          this.props.onToggleMap(true);
        } else {
          this.props.onToggleMap(false);
        }

        return {
          currentQueryParams,
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  render() {
    const {
      intl,
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      sortConfig,
      isRentPreference,
      isMapVisible,
      onToggleMap,
      currentUser,
      onAddToWishList,
      currentWishListListingId,
      addToWishListInProgress,
      addToWishListError,
    } = this.props;

    const preferenceFilter = urlQueryParams['pub_preference'];
    const categoryFilter = urlQueryParams['pub_category'];
    const subCategoryFilter = urlQueryParams['pub_subcategory'];

    // Generate subcategory filter when both
    // preference and category is selected
    const subCategoryFilterConfig = generateSubCategoryFilter(preferenceFilter, categoryFilter);

    // We merge subcategory filter with the primary filters
    const filteredPrimaryFilters = filterConfig.filter(f => f.group === 'primary');
    const mergedFilters =
      subCategoryFilterConfig && filteredPrimaryFilters.concat(subCategoryFilterConfig);
    const subCategoryIndex = mergedFilters?.findIndex(filter => filter.id === 'subcategory');

    // We need to ensure that we also move the subcategory
    // filter just after the category one
    const primaryFilters = subCategoryFilterConfig
      ? arrayMove(mergedFilters, subCategoryIndex, 3)
      : filteredPrimaryFilters;

    // Generate secondary filters
    const secondaryFiltersConfig = generateSecondaryFilters(
      {
        preference: preferenceFilter,
        category: categoryFilter,
        subcategory: subCategoryFilter,
      },
      intl
    );

    const filteredSecondaryFilters = filterConfig.filter(f => f.group !== 'primary');
    const secondaryFilters = secondaryFiltersConfig
      ? filteredSecondaryFilters.concat(secondaryFiltersConfig)
      : filteredSecondaryFilters;
    const hasSecondaryFilters = !!(secondaryFilters && secondaryFilters.length > 0);

    // Mobile filters
    const mergedMobileFilters =
      subCategoryFilterConfig && filterConfig.concat(subCategoryFilterConfig);
    const mobileSubCategoryIndex = mergedMobileFilters?.findIndex(
      filter => filter.id === 'subcategory'
    );
    const mobileFiltersWithSubCategory = subCategoryFilterConfig
      ? arrayMove(mergedMobileFilters, mobileSubCategoryIndex, 3)
      : filterConfig;
    const mobileFilters = secondaryFiltersConfig
      ? mobileFiltersWithSubCategory.concat(secondaryFiltersConfig)
      : mobileFiltersWithSubCategory;

    // Selected aka active filters
    const selectedFilters = validFilterParams(urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;
    const maxPriceForPriceFilter =
      categoryFilter === BIKES_CATEGORY || categoryFilter === FRAMES_CATEGORY ? 10000 : 1000;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {};
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const classes = classNames(rootClassName || css.searchResultContainer, className, {
      [css.searchResultContainerExpanded]: isMapVisible,
    });

    return (
      <div className={classes}>
        <SearchFiltersPrimary
          className={css.searchFiltersPrimary}
          sortByComponent={sortBy('desktop')}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          isMapVisible={isMapVisible}
          onToggleMap={onToggleMap}
          {...propsForSecondaryFiltersToggle}
        >
          {primaryFilters.map(config => {
            // We need to ensure that we remove dates
            // filter when preference is rent
            if (config.id === DATES_FILTER) {
              if (!isRentPreference) return null;
            } else if (
              config.id === SELL_CATEGORIES_FILTER ||
              config.id === RENT_CATEGORIES_FILTER
            ) {
              // We are making sure that we are only showing the correct
              // category filters based on selected preference
              if (!preferenceFilter || config.preference !== preferenceFilter) return null;
            } else if (config.id === STOCK_FILTER) {
              if (!preferenceFilter) return null;
              else if (preferenceFilter === RENT_PREFERENCE) return null;
            } else if (config.id === MOBILE_STOCK_FILTER) {
              return null;
            }
            return (
              <FilterComponent
                key={`SearchFiltersPrimary.${config.id}`}
                idPrefix="SearchFiltersPrimary"
                filterConfig={config}
                urlQueryParams={urlQueryParams}
                initialValues={this.initialValues}
                getHandleChangedValueFn={this.getHandleChangedValueFn}
                showAsPopup
                contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                maxPriceForPriceFilter={maxPriceForPriceFilter}
              />
            );
          })}
        </SearchFiltersPrimary>
        <SearchFiltersMobile
          className={classNames(css.searchFiltersMobile, {
            [css.searchFiltersRentMobile]: isMapVisible,
          })}
          urlQueryParams={urlQueryParams}
          sortByComponent={sortBy('mobile')}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          resetAll={this.resetAll}
          selectedFiltersCount={selectedFiltersCount}
          isRentPreference={isRentPreference}
          onToggleMap={onToggleMap}
          isMapVisible={isMapVisible}
        >
          {mobileFilters.map(config => {
            // We need to ensure that we remove dates
            // filter when preference is rent
            if (config.id === DATES_FILTER) {
              if (!isRentPreference) return null;
            } else if (
              config.id === SELL_CATEGORIES_FILTER ||
              config.id === RENT_CATEGORIES_FILTER
            ) {
              // We are making sure that we are only showing the correct
              // category filters based on selected preference
              if (!preferenceFilter || config.preference !== preferenceFilter) return null;
            } else if (config.id === MOBILE_STOCK_FILTER) {
              if (!preferenceFilter) return null;
              else if (preferenceFilter === RENT_PREFERENCE) return null;
            } else if (config.id === STOCK_FILTER) return null;

            const isDefault = config.searchType === DEFAULT_FILTER_TYPE;

            // We need to ensure that we remove all default filters
            // when user have selected either category or sub-category
            if (config.searchType === DEFAULT_FILTER_TYPE) {
              if (categoryFilter || subCategoryFilter) return null;
            }

            if (isRentPreference) {
              // If it's a rent preference we ensure that we keep
              // only BRAND and FEATURES filters
              if (isDefault && config.id !== BRAND_FILTER && config.id !== FEATURES_FILTER)
                return null;
            } else {
              // If it's a buy preference we need to remove the
              // FEATURES filter
              if (config.id === FEATURES_FILTER) return null;
            }

            return (
              <FilterComponent
                key={`SearchFiltersMobile.${config.id}`}
                idPrefix="SearchFiltersMobile"
                filterConfig={config}
                urlQueryParams={urlQueryParams}
                initialValues={this.initialValues}
                getHandleChangedValueFn={this.getHandleChangedValueFn}
                liveEdit
                showAsPopup={false}
                isSelectedByDefault={
                  config.id === 'mobile-stock' &&
                  typeof urlQueryParams[STOCK_FILTER] === 'undefined'
                }
              />
            );
          })}
        </SearchFiltersMobile>
        {isSecondaryFiltersOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersSecondary
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              applyFilters={this.applyFilters}
              cancelFilters={this.cancelFilters}
              resetAll={this.resetAll}
              onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
            >
              {secondaryFilters.map(config => {
                // We need to ensure that we remove all default filters
                // when user have selected either category or sub-category
                if (config.searchType === DEFAULT_FILTER_TYPE) {
                  if (categoryFilter || subCategoryFilter) return null;
                }

                if (isRentPreference) {
                  // If it's a rent preference we ensure that we keep
                  // only BRAND and FEATURES filters
                  if (config.id !== BRAND_FILTER && config.id !== FEATURES_FILTER) return null;
                } else {
                  // If it's a buy preference we need to remove the
                  // FEATURES filter
                  if (config.id === FEATURES_FILTER) return null;
                }

                return (
                  <FilterComponent
                    key={`SearchFiltersSecondary.${config.id}`}
                    idPrefix="SearchFiltersSecondary"
                    filterConfig={config}
                    urlQueryParams={urlQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    showAsPopup={false}
                  />
                );
              })}
            </SearchFiltersSecondary>
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}

            <SearchResultsPanel
              className={css.searchListingsPanel}
              listings={listings}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              isMapVisible={isMapVisible}
              currentUser={currentUser}
              onAddToWishList={onAddToWishList}
              currentWishListListingId={currentWishListListingId}
              addToWishListInProgress={addToWishListInProgress}
              addToWishListError={addToWishListError}
            />
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default MainPanel;
